import React from 'react';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Table, TablePaginationConfig } from 'antd';
import { IMostViewed } from '../../../../../hooks/userViews';

interface MostViewedReportsTableProps {
    order: 'ascend' | 'descend' | null;
    handleTableChange: (pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<IMostViewed> | SorterResult<IMostViewed>[]) => void;
    loading: boolean;
    data: IMostViewed[] | undefined;
}

const MostViewedReportsTable = ({ order, handleTableChange, loading, data }: MostViewedReportsTableProps) => {
    const columns = [
        {
            align: 'center' as const,
            title: 'Report Name',
            dataIndex: 'name',
            key: 'name',
            render: (dom: React.ReactNode) => dom || '(UNNAMED REPORT)',
        },
        {
            align: 'center' as const,
            title: 'Total Views',
            dataIndex: 'viewCount',
            key: 'viewCount',
            sorter: true,
            sortOrder: order,
        },
    ];

    return (
        <div style={{ height: '420px', overflowY: 'auto' }}>
            <Table<IMostViewed>
                bordered
                pagination={false}
                size="small"
                loading={loading}
                dataSource={data || []}
                columns={columns}
                rowKey="id"
                onChange={handleTableChange}
                scroll={{ y: 370 }}
            />
        </div>
    )
}

export default MostViewedReportsTable;
