import React, { useEffect, useState } from 'react';
import { Pagination, Select, TablePaginationConfig, Typography } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import ContentCard from '../../../Common/ContentCard';
import MostViewedReportsTable from './MostViwedTable';
import { EnumTimeOptions, IMostViewed, useGetMostViewed } from '../../../../hooks/userViews';
import { TIME_FILTERS } from '../../Reports/constants';

const MostViewedReports = () => {
    const [order, setOrder] = useState<'ascend' | 'descend' | null>('descend');
    const [paginationOptions, setPaginationOptions] = useState<{ current: number; pageSize: number }>({
        current: 1,
        pageSize: 10,
    });
    const [timeFilter, setTimeFilter] = useState<EnumTimeOptions>(EnumTimeOptions.ALL_TIME);
    const { data, fetch, loading } = useGetMostViewed();

    useEffect(() => {
        const sortOrder = order === 'ascend' ? 'ASC' : 'DESC';

        fetch({
            order: sortOrder,
            page: paginationOptions.current,
            pageSize: paginationOptions.pageSize,
            timeFilter,
        });
    }, [order, paginationOptions, timeFilter]);

    const handleTimeFilterChange = (value: string) => {
        setTimeFilter(value as EnumTimeOptions);
        setPaginationOptions({ ...paginationOptions, current: 1 });
    };


    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<IMostViewed> | SorterResult<IMostViewed>[]) => {
        const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;

        if (sortOrder === 'ascend' || sortOrder === 'descend') {
            setOrder(sortOrder);
        } else {
            setOrder(null);
        }
        setPaginationOptions({
            current: pagination.current || 1,
            pageSize: pagination.pageSize || 25,
        });
    };

    return (
        <ContentCard bordered padding={20} height='100%'>
            <div className='flex-col gap-20'>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Typography.Title level={2} style={{ color: '#464646' }}>
                        Most Viewed Reports
                    </Typography.Title>
                    <Select
                        size='middle'
                        value={timeFilter}
                        onChange={handleTimeFilterChange}
                        style={{ width: 120 }}
                    >
                        {TIME_FILTERS.map(filter => (
                            <Select.Option key={filter.value} value={filter.value}>
                                {filter.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <Pagination
                        showLessItems
                        size='small'
                        responsive
                        current={paginationOptions.current}
                        pageSize={paginationOptions.pageSize}
                        total={data?.data?.total || 0}
                        showSizeChanger
                        pageSizeOptions={['10', '25', '50', '100']}
                        onChange={(page, pageSize) => setPaginationOptions({ current: page, pageSize })}
                    />
                </div>
                <MostViewedReportsTable
                    order={order}
                    data={data?.data.mostViewedReports}
                    handleTableChange={handleTableChange}
                    loading={loading}
                />
            </div>
        </ContentCard>
    );
}

export default MostViewedReports;
